import { ChakraProvider } from '@chakra-ui/react'
import { ThemeProvider } from '@micin-jp/curon-ui'
import React, { useContext } from 'react'
import { useClientSetting } from 'src/clientSetting/Provider'
import { clientTheme } from './clientTheme'
import { ThemeContext } from './Context'

// クライアントに応じたテーマを提供します
export const ClientThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const clientSetting = useClientSetting()
  const theme = clientTheme[clientSetting.id]

  return (
    <ThemeContext.Provider
      value={{
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

// クライアントに応じたテーマを適用したChakraProvider
export const ChakraWithClientThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ClientThemeProvider>
      <ThemeContext.Consumer>
        {value => <ChakraProvider theme={value.theme}>{children}</ChakraProvider>}
      </ThemeContext.Consumer>
    </ClientThemeProvider>
  )
}

// @micin-jp/curon-uiの自作したテーマ
export const CuronUIThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return <ThemeProvider>{children}</ThemeProvider>
}

export const useTheme = () => useContext(ThemeContext)
